import {
  NAV_ITEM_TYPE_COLLAPSE,
  NAV_ITEM_TYPE_ITEM,
} from "constants/navigation.constant";

import { APP_PREFIX_PATH } from "constants/route.constant";

const navigationConfig = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    // translateKey: "nav.dashboard",
    icon: "home",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "cDashboard",
    path: `${APP_PREFIX_PATH}/counsellor/dashboard`,
    title: "Counsellor Dashboard",
    // translateKey: "nav.cDashboard",
    icon: "cDashboard",
    type: NAV_ITEM_TYPE_ITEM,
    authority: ["SUPER-ADMIN"],
    subMenu: [],
  },
  {
    key: "courseExplorer",
    path: `${APP_PREFIX_PATH}/course-explorer`,
    title: "Course Explorer",
    // translateKey: "nav.courseExplorer",
    icon: "courseExplorer",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "leads",
    path: `${APP_PREFIX_PATH}/leads`,
    title: "My Leads",
    // translateKey: "nav.leads",
    icon: "leads",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "inquiries",
    path: `${APP_PREFIX_PATH}/inquiries`,
    title: "Inquiries",
    // translateKey: "nav.inquiries",
    icon: "inquiries",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "applicationTabs",
    path: `${APP_PREFIX_PATH}/applications/status`,
    title: "My Applications",
    // translateKey: "applicationScrumBoard",
    icon: "scrumBoard",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "taskManagement",
    path: `${APP_PREFIX_PATH}/management/tasks`,
    title: "Task Management",
    // translateKey: "taskManagement",
    icon: "taskManagement",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: "managePayments",
    path: `${APP_PREFIX_PATH}/payments`,
    title: "Manage Payments",
    // translateKey: "managePayments",
    icon: "managePayments",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },

  {
    key: "activityLog",
    path: `${APP_PREFIX_PATH}/activity-log`,
    title: "Activity Log",
    // translateKey: "nav.timeline",
    icon: "timeline",
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },

  {
    key: "callLogs",
    path: "",
    title: "Call Logs",
    // translateKey: "callLogs",
    icon: "callLogs",
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: [],
    subMenu: [
      {
        key: "callLog",
        path: `${APP_PREFIX_PATH}/call-log`,
        title: "Call Log",
        // translateKey: "nav.callLog",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "callBackLog",
        path: `${APP_PREFIX_PATH}/callback-log`,
        title: "Call Backlog",
        translateKey: "nav.callBackLog",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
    ],
  },
  {
    key: "statistics",
    path: `${APP_PREFIX_PATH}/statistics`,
    title: "Statistics",
    // translateKey: "nav.statistics",
    icon: "statistics",
    type: NAV_ITEM_TYPE_ITEM,
    authority: ["SUPER-ADMIN"],
    subMenu: [],
  },
  {
    key: "analytics",
    path: `${APP_PREFIX_PATH}/analytics`,
    title: "Analytics",
    // translateKey: "nav.analytics",
    icon: "analytics",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "settings",
    path: "",
    title: "Settings",
    // translateKey: "settings",
    icon: "settings",
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: [],
    subMenu: [
      {
        key: "counsellorManagement",
        path: `${APP_PREFIX_PATH}/counsellor-management`,
        title: "Counsellor Management",
        translateKey: "nav.appsAccount.counsellorManagement",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN"],
        subMenu: [],
      },
      {
        key: "intakeManagement",
        path: `${APP_PREFIX_PATH}/intake-management`,
        title: "Intake Management",
        translateKey: "nav.appsAccount.intakeManagement",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN", "INTAKE-MASTER"],
        subMenu: [],
      },
      {
        key: "eventManagement",
        path: `${APP_PREFIX_PATH}/event-management`,
        title: "Event Management",
        translateKey: "nav.appsAccount.eventManagement",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN"],
        subMenu: [],
      },
      {
        key: "universityList",
        path: `${APP_PREFIX_PATH}/universities`,
        title: "Universities",
        translateKey: "nav.appsAccount.universityList",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "courseList",
        path: `${APP_PREFIX_PATH}/courses`,
        title: "Courses",
        translateKey: "nav.appsAccount.courseList",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "bulkCommisionUpdate",
        path: `${APP_PREFIX_PATH}/commissions`,
        title: "Agent & Commission Manager",
        translateKey: "nav.appsAccount.bulkCommisionUpdate",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN"],
        subMenu: [],
      },
      {
        key: "subAgents",
        path: `${APP_PREFIX_PATH}/sub-agents`,
        title: "Sub Agents",
        translateKey: "nav.appsAccount.subAgents",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "userPermissions",
        path: `${APP_PREFIX_PATH}/user-permissions`,
        title: "User Permissions",
        translateKey: "nav.appsAccount.userPermissions",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN"],
        subMenu: [],
      },
      {
        key: "paymentEstimation",
        path: `${APP_PREFIX_PATH}/estimations`,
        title: "Payment Estimation",
        translateKey: "nav.appsAccount.paymentEstimation",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: ["ADMIN"],
        subMenu: [],
      },
      {
        key: "campaignMailing",
        path: `${APP_PREFIX_PATH}/campaigns/mailing`,
        title: "Emailing",
        translateKey: "nav.appsAccount.campaignMailing",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "banner",
        path: `${APP_PREFIX_PATH}/banner`,
        title: "Banner Management",
        translateKey: "nav.appsAccount.banner",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "forms",
        path: `${APP_PREFIX_PATH}/forms`,
        title: "Forms",
        translateKey: "nav.appsAccount.forms",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: "pathways",
        path: `${APP_PREFIX_PATH}/pathways`,
        title: "Pathways",
        translateKey: "nav.appsAccount.pathways",
        icon: "",
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
    ],
  },
];

export default navigationConfig;
