import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiGetRouteRoleMap,
  apiSignIn,
  apiSignOut,
  apiSignUp,
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { getUser } from "../../services/UserService";
import toast from "../../components/ui/toast";
import Notification from "../../components/ui/Notification";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { apiGetPusherBeamCredentials } from "services/RealtimeNotificationService";
import { updateRoutes } from "store/auth/protectedRoutesSlice";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);

      if (resp.data) {
        const { token } = resp.data;

        dispatch(onSignInSuccess({ payload: token }));
        localStorage.setItem("token", token);

        const request = await getUser();
        const user = request.data;

        if (user) {
          //pusherbeam init at login
          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: `${appConfig.apiPrefix}/user/pusher/beams-auth`,
            queryParams: {
              // someQueryParam: "parameter-content", // URL query params your auth endpoint needs
            },
            headers: {
              [`${REQUEST_HEADER_AUTH_KEY}`]: TOKEN_TYPE + " " + token,
            },
          });

          const res = await apiGetPusherBeamCredentials();
          if (res.data) {
            const instanceId = res.data;

            const beamsClient = new PusherPushNotifications.Client({
              instanceId,
            });

            beamsClient
              .start()
              .then(() => {
                console.log("Successfully started Pusher Beams");
                beamsClient
                  .setUserId(user["keyCloakUserId"], beamsTokenProvider)
                  .then(() => {
                    console.log("Successfully authenticated with Pusher Beams");
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          }

          dispatch(
            setUser({
              avatar: user["displayPicture"],
              username: user["userName"],
              counsellorId: user["counsellorId"],
              keycloakUserId: user["keyCloakUserId"],
              authority: user["roles"]
                ? user["roles"].map((element) => element.toUpperCase())
                : ["USER"],
              email: user["emailId"],
              firstName: user["firstname"],
              lastName: user["lastName"],
            })
          );

          const routeResponse = await apiGetRouteRoleMap();
          if (routeResponse.data) {
            const routeRoleMap = routeResponse.data;
            localStorage.setItem("routeRoleMap", JSON.stringify(routeRoleMap));

            dispatch(updateRoutes([]));
            dispatch(updateRoutes(routeRoleMap));
          }
        } else {
          toast.push(
            <Notification title={"Warning"} type={"danger"}>
              Could not get user details.
            </Notification>
          );
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      console.log(errors);
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
        type: errors?.response?.data?.type || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                username: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.removeItem("token");
    localStorage.removeItem("routeRoleMap");

    toast.push(
      <Notification title={"Signed out"} type={"success"}></Notification>
    );
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    await apiSignOut();
    const res = await apiGetPusherBeamCredentials();
    if (res.data) {
      const instanceId = res.data;

      const beamsClient = new PusherPushNotifications.Client({
        instanceId,
      });
      beamsClient
        .clearAllState()
        .then(() => console.log("Beams state has been cleared"))
        .catch((e) => console.error("Could not clear Beams state", e));
      beamsClient
        .stop()
        .then(() => console.log("Beams SDK has been stopped"))
        .catch((e) => console.error("Could not stop Beams SDK", e));
    }
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
