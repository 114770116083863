import { createSlice } from "@reduxjs/toolkit";
import navigationConfig from "configs/navigation.config";
import { protectedRoutes } from "configs/routes.config";

const initializeNavList = (navList) => {
  return navList.map((menuItem) => ({
    key: menuItem.key,
    authority: menuItem.authority || [],
    subMenu:
      menuItem.subMenu?.length > 0 ? initializeNavList(menuItem.subMenu) : [],
  }));
};

const initialState = {
  proRoutesList: protectedRoutes.map((proRoute) => {
    return {
      key: proRoute?.key,
      authority: proRoute?.authority,
    };
  }),
  proNavList: initializeNavList(navigationConfig),
};

const updateAuthorityForNavList = (navList, updatedAuthorities) => {
  return navList.map((menuItem) => {
    if (menuItem.subMenu?.length > 0) {
      const updatedSubMenu = updateAuthorityForNavList(
        menuItem.subMenu,
        updatedAuthorities
      );

      // Check if all submenus have the same authority
      const allAuthoritiesMatch = updatedSubMenu.every(
        (subItem) =>
          JSON.stringify(subItem.authority) ===
          JSON.stringify(updatedSubMenu[0].authority)
      );

      return {
        ...menuItem,
        subMenu: updatedSubMenu,
        authority: allAuthoritiesMatch
          ? updatedSubMenu[0].authority
          : menuItem.authority,
      };
    }

    const matchingAuthority = updatedAuthorities.find(
      (authorityItem) => authorityItem.key === menuItem.key
    );

    return {
      ...menuItem,
      authority: matchingAuthority
        ? matchingAuthority.authority
        : menuItem.authority,
    };
  });
};

const proRoutesSlice = createSlice({
  name: "proRoutes",
  initialState,
  reducers: {
    updateRoutes(state, action) {
      const updatedRoutes = state.proRoutesList.map((route) => {
        const matchingFeature = action.payload.find(
          (feature) => feature.frontendFeature === route.key
        );
        if (matchingFeature) {
          return {
            ...route,
            authority: matchingFeature.roles,
          };
        }
        return route;
      });
      state.proRoutesList = updatedRoutes;
      const updatedAuthorities = action.payload.map((feature) => ({
        key: feature.frontendFeature,
        authority: feature.roles,
      }));
      state.proNavList = updateAuthorityForNavList(
        state.proNavList,
        updatedAuthorities
      );
    },
  },
});

export const { updateRoutes } = proRoutesSlice.actions;

export default proRoutesSlice.reducer;
