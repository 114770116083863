import NumberFormat from "react-number-format";
import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { cloneDeep, find, lowerCase, omit } from "lodash";
import { commentTypes } from "constants/app.constant";
dayjs.extend(customParseFormat);

export const isPdf = (url) => {
  const extension = url.split(".").pop().toLowerCase();
  if (extension === "pdf") {
    return true;
  } else {
    return false;
  }
};

export function getFormattedIntake(dateString) {
  const date = dayjs(dateString, "YYYY-MM-DD HH:mm:ss");
  return date.format("MMM YYYY");
}

export function reformatFilters(filters) {
  const reformattedFilters = {};

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const [min, max] = filters[key];
      reformattedFilters[`min${key.charAt(0).toUpperCase() + key.slice(1)}`] =
        min;
      reformattedFilters[`max${key.charAt(0).toUpperCase() + key.slice(1)}`] =
        max;
    }
  }

  return reformattedFilters;
}

export function formatDate(json) {
  const { date, time } = json;

  const dateTime = new Date(
    date.year,
    date.month - 1,
    date.day,
    time.hour,
    time.minute,
    time.second
  );

  const formattedDateTime = dateTime.toLocaleString();

  return formattedDateTime;
}

export function reverseReformatFilters(reformattedFilters) {
  const filters = {};

  for (const key in reformattedFilters) {
    if (reformattedFilters.hasOwnProperty(key)) {
      const originalKey = key.replace(/^min|^max/, "");
      const lowerOriginalKey =
        originalKey.charAt(0).toLowerCase() + originalKey.slice(1);

      if (!filters[lowerOriginalKey]) {
        filters[lowerOriginalKey] = [];
      }

      if (key.startsWith("min")) {
        filters[lowerOriginalKey][0] = reformattedFilters[key];
      } else if (key.startsWith("max")) {
        filters[lowerOriginalKey][1] = reformattedFilters[key];
      }
    }
  }

  return filters;
}

export function convertIntakesDates(intakes) {
  return intakes.map((intake) => {
    const startDateTime = intake.startDate
      ? new Date(
          intake.startDate.date.year,
          intake.startDate.date.month - 1, // JavaScript months are zero-based, so we subtract 1
          intake.startDate.date.day,
          intake.startDate.time.hour,
          intake.startDate.time.minute,
          intake.startDate.time.second
        )
      : null;

    const endDateTime = intake.endDate
      ? new Date(
          intake.endDate.date.year,
          intake.endDate.date.month - 1,
          intake.endDate.date.day,
          intake.endDate.time.hour,
          intake.endDate.time.minute,
          intake.endDate.time.second
        )
      : null;

    return {
      ...intake,
      startDate: startDateTime,
      endDate: endDateTime,
    };
  });
}

export const PriceAmount = ({ amount, currency }) => {
  return (
    <NumberFormat
      displayType="text"
      value={(Math.round(amount * 100) / 100).toFixed(2)}
      prefix={`${currency === "GBP" ? "£" : "$"} `}
      thousandSeparator={true}
    />
  );
};

export function convertToYearsOrMonths(value) {
  const years = Math.floor(value);
  const months = Math.round((value - years) * 12);

  if (years > 0 && months > 0) {
    return `${years} years, ${months} months`;
  } else if (years > 0) {
    return `${years} years`;
  } else if (months > 0) {
    return `${months} months`;
  } else {
    return "0 months";
  }
}

export const findLabelByValue = (value) => {
  const option = find(commentTypes.slice(1), { value });
  return option ? option.label : null;
};

export const findRatePrefix = (value) => {
  if (value == null) {
    return null;
  }
  switch (value) {
    case "1":
      return " this day";
      break;
    case "7":
      return " this week";
      break;
    case "1month":
      return " this month";
      break;
    case "3months":
      return " these 3 months";
      break;
    case "6months":
      return " these 6 months";
      break;
    case "12months":
      return " these 12 months";
      break;
    case "a":
    default:
      return null;
  }
};

export const findMatchingObjects = (options, selected) => {
  console.log("options,selected", options, selected);
  return options.filter((option) =>
    selected?.find((sel) => lowerCase(sel.value) === lowerCase(option.value))
  );
};

export const updateAuthorityForNavList = (navigationConfig, proNavList) => {
  // Flatten proNavList into a single array, including submenus
  const flattenProNavList = (navList) => {
    let flatList = [];

    navList.forEach((menuItem) => {
      // Add the current item
      flatList.push({
        key: menuItem.key,
        authority: menuItem.authority,
      });

      // If there are submenus, recursively flatten them
      if (menuItem.subMenu && menuItem.subMenu?.length > 0) {
        flatList = flatList.concat(flattenProNavList(menuItem.subMenu));
      }
    });

    return flatList;
  };

  // Flatten the proNavList before comparison
  const flatProNavList = flattenProNavList(proNavList);

  // Helper function to recursively update authorities for submenus
  const updateSubMenuAuthority = (navConfig, navList) => {
    return navConfig.map((menuItem) => {
      // Find matching item in the flattened proNavList by key
      const matchingNavListItem = navList.find(
        (listItem) => listItem.key === menuItem.key
      );

      // If there's a matching item, update authority
      if (matchingNavListItem) {
        // console.log("matchingNavListItem", matchingNavListItem, menuItem);
        menuItem.authority =
          matchingNavListItem.authority || menuItem.authority;
      }

      // If the menu item has submenus, recursively update their authority
      if (menuItem.subMenu && menuItem.subMenu?.length > 0) {
        menuItem.subMenu = updateSubMenuAuthority(menuItem.subMenu, navList);
      }
      // console.log("matchingNavLis", menuItem);

      return menuItem;
    });
  };

  // Call the recursive function on the navigationConfig and return the updated navigationConfig
  return updateSubMenuAuthority(navigationConfig, flatProNavList);
};

export const getStatusMessage = (statusCode) => {
  switch (statusCode) {
    case 400:
      return "Bad Request!";
    case 403:
      return "Access Denied!";
    case 404:
      return "Not Found!";
    case 500:
      return "Internal Server Error!";
    default:
      return "Something Went Wrong!";
  }
};

export const removeProperty = (obj, propName) => {
  if (!obj || typeof obj !== "object") return obj;

  return omit(obj, propName);
};
