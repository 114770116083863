import { combineReducers } from "@reduxjs/toolkit";
import session from "./sessionSlice";
import user from "./userSlice";
import proRoutes from "./protectedRoutesSlice";

const reducer = combineReducers({
  session,
  user,
  proRoutes,
});

export default reducer;
