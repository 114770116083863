import React from "react";
import authRoute from "./authRoute";
import { APP_PREFIX_PATH } from "constants/route.constant";
// import { ADMIN, USER } from "constants/roles.constant";

export const publicRoutes = [...authRoute];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/crm/CrmDashboard")),
    authority: [],
  },
  {
    key: "cDashboard",
    path: `${APP_PREFIX_PATH}/counsellor/dashboard`,
    component: React.lazy(() => import("views/counsellor-dashboard")),
    authority: ["SUPER-ADMIN"],
  },
  {
    key: "leadList",
    path: `${APP_PREFIX_PATH}/leads`,
    component: React.lazy(() => import("views/leads")),
    authority: [],
  },
  {
    key: "followUps",
    path: `${APP_PREFIX_PATH}/follow-ups`,
    component: React.lazy(() => import("views/leads")),
    authority: [],
  },
  {
    key: "inquiries",
    path: `${APP_PREFIX_PATH}/inquiries`,
    component: React.lazy(() => import("views/invited-leads")),
    authority: [],
  },
  // {
  //   key: "applicationScrumBoard",
  //   path: `${APP_PREFIX_PATH}/applications`,
  //   component: React.lazy(() =>
  //     import("views/application-scrumboard/ScrumBoard")
  //   ),
  //   authority: [],
  // },
  {
    key: "applicationTabs",
    path: `${APP_PREFIX_PATH}/applications/status`,
    component: React.lazy(() => import("views/realtime-application")),
    authority: [],
  },
  {
    key: "applicationTabs",
    path: `${APP_PREFIX_PATH}/applications/table`,
    component: React.lazy(() => import("views/realtime-application")),
    authority: [],
  },
  {
    key: "applicationTabs",
    path: `${APP_PREFIX_PATH}/applications/targets`,
    component: React.lazy(() => import("views/realtime-application")),
    authority: [],
  },
  {
    key: "applicationTabs",
    path: `${APP_PREFIX_PATH}/applications/monitoring`,
    component: React.lazy(() => import("views/realtime-application")),
    authority: [],
  },
  {
    key: "analytics",
    path: `${APP_PREFIX_PATH}/analytics`,
    component: React.lazy(() => import("views/analytics")),
    authority: [],
  },
  {
    key: "taskManagement",
    path: `${APP_PREFIX_PATH}/management/tasks`,
    component: React.lazy(() => import("views/task-management")),
    authority: [],
  },
  {
    key: "taskManagement",
    path: `${APP_PREFIX_PATH}/management/daily-updates`,
    component: React.lazy(() => import("views/task-management")),
    authority: [],
  },
  {
    key: "taskDetails",
    path: `${APP_PREFIX_PATH}/task-details`,
    component: React.lazy(() => import("views/task-details")),
    authority: [],
  },
  {
    key: "paymentDetails",
    path: `${APP_PREFIX_PATH}/payment-details`,
    component: React.lazy(() => import("views/payment-details")),
    authority: [],
  },
  {
    key: "managePayments",
    path: `${APP_PREFIX_PATH}/payments`,
    component: React.lazy(() => import("views/manage-payments")),
    authority: [],
  },
  {
    key: "profileDetails",
    path: `${APP_PREFIX_PATH}/profile-details`,
    component: React.lazy(() => import("views/crm/CustomerDetail")),
    authority: [],
  },
  {
    key: "activityLog",
    path: `${APP_PREFIX_PATH}/activity-log`,
    component: React.lazy(() => import("views/crm/ActivityLog")),
    authority: [],
  },
  {
    key: "callLog",
    path: `${APP_PREFIX_PATH}/call-log`,
    component: React.lazy(() => import("views/call-logs")),
    authority: [],
  },
  {
    key: "callBackLog",
    path: `${APP_PREFIX_PATH}/callback-log`,
    component: React.lazy(() => import("views/callback-logs")),
    authority: [],
  },
  {
    key: "counsellorManagement",
    path: `${APP_PREFIX_PATH}/counsellor-management`,
    component: React.lazy(() => import("views/cousellor-management")),
    authority: ["ADMIN"],
  },
  {
    key: "intakeManagement",
    path: `${APP_PREFIX_PATH}/intake-management`,
    component: React.lazy(() => import("views/intake-management")),
    authority: ["ADMIN", "INTAKE-MASTER"],
  },
  {
    key: "eventManagement",
    path: `${APP_PREFIX_PATH}/event-management`,
    component: React.lazy(() => import("views/event-management")),
    authority: ["ADMIN"],
  },
  {
    key: "universityList",
    path: `${APP_PREFIX_PATH}/universities`,
    component: React.lazy(() => import("views/crm/Universities")),
    authority: [],
  },
  {
    key: "subAgents",
    path: `${APP_PREFIX_PATH}/sub-agents`,
    component: React.lazy(() => import("views/sub-agents")),
    authority: [],
  },

  {
    key: "courseList",
    path: `${APP_PREFIX_PATH}/courses`,
    component: React.lazy(() => import("views/courses/CourseList")),
    authority: [],
  },
  {
    key: "statistics",
    path: `${APP_PREFIX_PATH}/statistics`,
    component: React.lazy(() => import("views/RevenueStatistic")),
    authority: ["SUPER-ADMIN"],
  },
  {
    key: "bulkCommisionUpdate",
    path: `${APP_PREFIX_PATH}/commissions`,
    component: React.lazy(() => import("views/commisions")),
    authority: ["ADMIN"],
  },
  {
    key: "paymentEstimation",
    path: `${APP_PREFIX_PATH}/estimations`,
    component: React.lazy(() => import("views/estimations")),
    authority: ["ADMIN"],
  },
  {
    key: "userPermissions",
    path: `${APP_PREFIX_PATH}/user-permissions`,
    component: React.lazy(() => import("views/user-permissions")),
    authority: ["ADMIN"],
  },
  {
    key: "student",
    path: `${APP_PREFIX_PATH}/student`,
    component: React.lazy(() => import("views/account/KycForm")),
    authority: [],
  },
  {
    key: "courseExplorer",
    path: `${APP_PREFIX_PATH}/course-explorer`,
    component: React.lazy(() => import("views/course-explorer")),
    authority: [],
  },
  {
    key: "courseDetails",
    path: `${APP_PREFIX_PATH}/course-details`,
    component: React.lazy(() => import("views/courses/CourseDetails")),
    authority: [],
  },
  {
    key: "applicationTimeline",
    path: `${APP_PREFIX_PATH}/application`,
    component: React.lazy(() => import("views/application-timeline")),
    authority: [],
  },
  {
    key: "cousellorDetails",
    path: `${APP_PREFIX_PATH}/counsellor-details`,
    component: React.lazy(() => import("views/counsellor-details")),
    authority: [],
  },
  {
    key: "manageDatabase",
    path: `${APP_PREFIX_PATH}/database`,
    component: React.lazy(() => import("views/sales/ProductNew")),
    authority: [],
  },
  {
    key: "setupWizard",
    path: `${APP_PREFIX_PATH}/setup-wizard`,
    component: React.lazy(() => import("views/setup")),
    authority: [],
  },
  {
    key: "campaignMailing",
    path: `${APP_PREFIX_PATH}/campaigns/mailing`,
    component: React.lazy(() => import("views/campaigns")),
    authority: [],
  },
  {
    key: "banner",
    path: `${APP_PREFIX_PATH}/banner`,
    component: React.lazy(() => import("views/banner-management")),
    authority: [],
  },
  {
    key: "forms",
    path: `${APP_PREFIX_PATH}/forms`,
    component: React.lazy(() => import("views/form-builder")),
    authority: [],
  },
  {
    key: "pathways",
    path: `${APP_PREFIX_PATH}/pathways`,
    component: React.lazy(() => import("views/pathways")),
    authority: [],
  },
];
